<!--
 * @Descripttion: 团队okr导航
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-13 14:32:36
 * @LastEditors: tangjz
 * @LastEditTime: 2023-09-15 09:21:44
-->
<template>
  <el-affix :offset="71">
  <div class="okr-nav team-okr-nav scroll-bar">
      <div class="padding-16">
        <p class="back" @click="back">&lt;返回</p>
        <h3 v-if="treeIndex > 0">{{active.name}}</h3>
      </div>

      <template v-if="treeIndex > 0">
        <div class="line"></div>
        <div class="padding-16">
          <div class="position">
                <p @click="positionTree(0)">最上级</p>
                <p @click="positionTree(-1)">上一级</p>
            </div>
        </div>
      </template>

    <el-scrollbar>
      <ul class="padding-16 nav-list">
          <li v-for="(item, index) in teamData" :key="index" >
              <div class="items">
                  <template v-if="item.dept_id">
                    <el-tooltip :hide-after="50" v-if="substrText(item.name).includes('...')" class="box-item" effect="dark" :content="item.name" placement="top-start">
                      <p :class="{'title':true,'nav-item':true, 'active': item.dept_id === clickDeptId}" @click="clickItem(item)">{{substrText(item.name)}}</p>
                    </el-tooltip>
                      <p v-else :class="{'title':true,'nav-item':true, 'active': item.dept_id === clickDeptId}" @click="clickItem(item)">{{substrText(item.name)}}</p>
                      <el-icon @click="getChildrenTeam(item)"  v-if="item.children.length > 0 || item?.user"><i class="word-okr-down"></i></el-icon>
                  </template>

                  <template v-else>
                    <p :class="{'title':true,'nav-item':true, 'user': true, 'active': item.id === clickUserId}" @click="clickItem(item)">
                      <UserAvatar :user="item" size="24" fontSize="10" medalSize="36" :isPreview="false" :noPreview="false"></UserAvatar>

                    <el-tooltip :hide-after="50" v-if="substrText(item.name).includes('...')" class="box-item" effect="dark" :content="item.name" placement="top-start">
                      {{ substrText(item.name)}}
                    </el-tooltip>
                    <template v-else>{{ substrText(item.name)}}</template>
                    </p>
                  </template>
              </div>
          </li>

      </ul>
    </el-scrollbar>
  </div>
        <!-- {{teamData}} -->

  </el-affix>
</template>

<script>
import { reactive, toRefs, onMounted, computed } from 'vue'
import { getTeamList } from '@/apis/journal.js'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
export default {
  name: 'TeamOkrNav',
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      teamData: [],
      allTeamData: [], // 全部团队
      treeIndex: 0, // 当前点击的层级
      historyTeamData: [], // 记录历史点击记录
      active: {},
      clickDeptId: '',
      clickUserId: '',
      backData: computed(() => store.state.okr.menu)
    })

    onMounted(() => {
      getMyTeam()
    })

    // 获取我的团队信息
    const getMyTeam = () => {
      const data = { type: 2 }
      getTeamList(data).then(({ data, code }) => {
        if (code === 0) {
          state.allTeamData = data

          const children = state.allTeamData.children
          state.teamData = [{ name: data.name, children: [], parent_id: data.parent_id, dept_id: data.dept_id }, ...children]
          state.historyTeamData.push({ children: state.teamData }) // 记录历史记录

          // 默认选择的第一个id
          state.clickDeptId = route.params.allTargetID ? Number(route.params.allTargetID) : data.dept_id
        }
      })
    }

    // 获取子部门
    const getChildrenTeam = ({ children, dept_id: deptId, name, parent_id: parentId, user }) => {
      const childrenLenthStatus = children.length > 0

      const childrenData = childrenLenthStatus ? children : user

      state.active = {
        deptId,
        name,
        parentId: childrenLenthStatus ? parentId : user
      }

      state.historyTeamData.push({ children: childrenData, active: state.active })

      state.teamData = childrenData
      state.treeIndex++
    }

    // 层级操作
    const positionTree = (index) => {
      state.treeIndex = index === -1 ? --state.treeIndex : index

      state.teamData = state.historyTeamData[state.treeIndex].children

      state.active = state.historyTeamData[state.treeIndex].active

      const historyTeamData = [...state.historyTeamData]
      if (index === -1) {
        state.historyTeamData = [...historyTeamData.splice(0, state.treeIndex + 1)]
      }
    }

    // 截取文字
    const substrText = (name) => {
      if (name.length > 5) {
        return `${name.substr(0, 5)}...`
      }
      return name
    }

    // 选择列表
    const clickItem = ({ dept_id: deptId, id, name }) => {
      store.commit('okr/setAllTargetSel', name)
      state.clickDeptId = ''
      state.clickUserId = ''
      if (deptId) {
        router.push({ name: 'allTarget', params: { allTargetID: deptId }, query: { isDep: true } })
        state.clickDeptId = deptId
      } else if (id) {
        router.push({ name: 'allTarget', params: { allTargetID: id, tabsel: '成员目标', depId: state.active.deptId }, query: { isUser: true } })
        state.clickUserId = id
      }
    }

    // 返回
    const back = () => {
      let firstDep
      if (route.query.originDepId) {
        firstDep = state.backData.filter(i => i.name === '我的OKR')[0].children.filter(i => Number(i.dept_id) === Number(route.query.originDepId))[0]
      } else {
        firstDep = state.backData.filter(i => i.name === '我的OKR')[0].children[0]
      }
      router.push({ name: 'myokr', params: { id: firstDep.dept_id, depName: firstDep.name, queryDate: route.query.date ? JSON.stringify(route.query.date) : undefined } })
    }

    const componyId = computed(() => store.state.okr.allTargetId)
    if (route.params.allTargetID !== componyId.value) {
      router.push({ name: 'allTarget', params: { allTargetID: componyId.value }, query: { isDep: true } })
    }

    return {
      ...toRefs(state),
      getMyTeam,
      getChildrenTeam,
      positionTree,
      substrText,
      clickItem,
      back
    }
  }
}
</script>

<style lang="less" scoped>
@import url(../../../../../../assets/less/okrNav.less);
.el-affix {
  min-width: 165px;
}
.team-okr-nav {
  padding: 16px 0;
  color: #333;
  .padding-16 {
    padding: 0 12px 0 16px;
  }
  .back {
    font-size: 12px;
    cursor: pointer;
    &:hover {
        color: @active-text-color;
    }
  }

  h3 {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
  }

  .nav-list {
    li {
      margin-top: 10px;
      .items {
        display: flex;
        align-items: center;
      }
      .nav-item {
        margin-top: 0;
        width: 114px;
        word-break: keep-all;
        &.user {
          justify-content: flex-start
        }
        &:hover {
           color: @active-text-color;
        }
      }
      .word-okr-down {
        display: block;
        width: 12px;
        height: 12px;
        cursor: pointer;
        background: url('../../../../../../assets/img/work/word-okr-down-new.svg') center right no-repeat;
        &:hover {
           background: url('../../../../../../assets/img/work/word-okr-down-active-new.svg') center right no-repeat;
        }
      }
    }
  }

  .position {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;

    p {
      font-size: 12px;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        color: @active-text-color;
      }
    }
  }

}
</style>
